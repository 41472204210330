import React, { Suspense } from 'react';
import { useState, useEffect } from 'react';

import {
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import { useEnvironmentContext } from "../Contexts/EnvironmentProvider";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import ListIcon from '@mui/icons-material/List';

import ScriptRouter from "../Router/ScriptRouter";
import SessionRouter from "../Router/SessionRouter";

import Header from "./Header";
import Footer from "../Footer";
import NavMenu from './NavMenu';

import NewNotificationIndicator from '../NewNotificationIndicator';

const RecordPage = React.lazy(() => import("../Record/RecordPage"));
const PreviewPage = React.lazy(() => import("../Preview/PreviewPage"));
const ProfilePage = React.lazy(() => import("../Profile/ProfilePage"));
const SessionsPage = React.lazy(() => import("../Session/Sessions"));
const ScriptsPage = React.lazy(() => import("../Scripts/Scripts"));
const ScriptEditorPage = React.lazy(() => import("../ScriptEditor/ScriptEditor"));

const AudioPage = React.lazy(() => import("../Audio/Audios"));
const AudioEditorPage = React.lazy(() => import("../Audio/AudioEditor"));

const DocumentsPage = React.lazy(() => import("../Write/Documents"));
const WritePage = React.lazy(() => import("../Write/WritePage"));

const TemplatesPage = React.lazy(() => import("../Templates/Templates"));
const TemplateEditorPage = React.lazy(() => import("../Templates/TemplateEditor"));
const TemplatePage = React.lazy(() => import("../Templates/Template"));

const FriendsPage = React.lazy(() => import("../Friends/Friends"));
const AddFriendPage = React.lazy(() => import("../Friends/AddFriend"));

const NotificationPage = React.lazy(() => import("../Notification/Notifications"));

const LoginPanel = React.lazy(() => import("../Login/LoginPanel"));

const AboutPage = React.lazy(() => import("../Misc/About"));
const GuidePage = React.lazy(() => import("../Misc/Guide"));
const PrivacyPage = React.lazy(() => import("../Misc/Privacy"));
const TermsPage = React.lazy(() => import("../Misc/Terms"));
const ContactPage = React.lazy(() => import('../Misc/Contact'));

const PilotQueryPage = React.lazy(() => import('../Misc/PilotQuery'));

const FaqPage = React.lazy(() => import('../Misc/Faq'));
const GlossaryPage = React.lazy(() => import('../Misc/Glossary'));
const MetricsPage = React.lazy(() => import('../Misc/Metrics'));
const MembershipPage = React.lazy(() => import('../Misc/Membership'));
const PaymentPage = React.lazy(() => import('../Misc/Subscription'));

const GuestLanding = React.lazy(() => import('../GuestLanding'));

const AzureSignIn = React.lazy(() => import('../Misc/Azure/SignIn'));
const AzureSignOut = React.lazy(() => import('../Misc/Azure/SignOut'));

const FreeSoundPOC = React.lazy(() => import('../Misc/FreeSoundPOC'));

export default function MainSite() {
  const { token, user, hasPrivilege } = useAuthContext();

  const { siteVariant } = useEnvironmentContext();

  const [backgroundUrl, setBackgroundUrl] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const getRandomBackground = () => {
      const now = new Date();

      const hour = now.getHours();

      let timeString = '';
      let count = 1;
      let qualifier = '';

      let counts = [[3, 3], [3, 3], [2, 3]];

      let sizes = ['landscape', 'portrait'];

      const sizeIndex = window.innerWidth < window.innerHeight ? 1 : 0;

      if (hour >= 6 && hour < 12) { timeString = '1-morning'; count = counts[0][sizeIndex]; }
      else if (hour >= 12 && hour < 19) { timeString = '2-afternoon'; count = counts[1][sizeIndex]; }
      else { timeString = '3-night'; count = counts[2][sizeIndex]; }


      const index = Math.floor(Math.random() * count) + 1;

      return `/BG/${timeString}-${sizes[sizeIndex]}${qualifier}-${index}.jpg`;
    };

    setBackgroundUrl(getRandomBackground());
  }, []);

  const theme = createTheme({
    palette: {
      button: {
        main: 'black'
      },
      modal: {
        main: '#fff'
      },
      theme: {
        main: siteVariant === 'default' ? "#BA58E9" : "#149af3"
      }
    }
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigateToRoot = () => {
    navigate('/', { replace: true });
  };

  const loggedIn = token !== null;

  const tabStyle: React.CSSProperties = { color: 'white', textTransform: 'none', fontSize: '10pt', whiteSpace: 'nowrap', minWidth: 'auto' };
  const selectedTabStyle: React.CSSProperties = { color: 'black', fontWeight: 'bold', fontSize: '10pt', textTransform: 'none', whiteSpace: 'nowrap', minWidth: 'auto' };

  const routesWithHeader = () => {
    return (
      <>
        {
          loggedIn &&
          <>
            <Route path="/scripts/edit/:scriptId" element={<ScriptEditorPage />} />
            <Route path="/scripts/create" element={<ScriptEditorPage />} />
            <Route path="/circle/invite" element={<AddFriendPage />} />

            {hasPrivilege('audio_library') &&
              <>
                <Route path="/audio" element={<AudioPage />} />
                <Route path="/audio/edit/:audioId" element={<AudioEditorPage />} />
                <Route path="/audio/create/:type" element={<AudioEditorPage />} />
              </>
            }

            {hasPrivilege('show_metrics') &&
              <Route path="/metrics" element={<MetricsPage />} />
            }
          </>
        }
        <Route path="/guide" element={<GuidePage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/membership" element={<MembershipPage />} />
        <Route path="/subscription" element={<PaymentPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/glossary" element={<GlossaryPage />} />
        <Route path="/scripts" element={<ScriptsPage />} />

        <Route path="/contact" element={<ContactPage />} />

        {siteVariant === 'school' &&
          <Route path="/pilot_program/id" element={<PilotQueryPage />} />
        }

        {hasPrivilege('draft') &&
          <>
            <Route path="/write" element={<DocumentsPage />} />
            <Route path="/templates" element={<TemplatesPage />} />
            <Route path="/templates/edit/:templateId" element={<TemplateEditorPage />} />
            <Route path="/templates/create" element={<TemplateEditorPage />} />
          </>
        }

        <Route path="/history" element={loggedIn ? <SessionsPage /> : <GuestLanding pageContext="productions" />} />
        <Route path="/circle" element={loggedIn ? <FriendsPage /> : <GuestLanding pageContext="friend list" />} />
        <Route path="/notifications" element={loggedIn ? <NotificationPage /> : <GuestLanding pageContext="messages" />} />
        <Route path='/freesound-poc' element={<FreeSoundPOC />} />
      </>
    );
  }

  const routesWithoutHeader = () => {
    return (
      <>
        <Route path="/members/:handle" element={<ProfilePage />} />
        <Route path="/profile/:userId" element={<ProfilePage />} />
        <Route path="/session/:sessionId" element={<RecordPage />} />
        <Route path="/write/:documentId" element={<WritePage />} />
        <Route path="/template/:templateId" element={<TemplatePage />} />

        <Route path="/s/:linkId" element={<SessionRouter />} />
        <Route path="/podcast-ep/:linkId" element={<PreviewPage />} />
        <Route path="/script/:scriptId" element={<ScriptRouter />} />
        {(siteVariant === 'school' && !user) ?
          <Route path="/" element={<Navigate to="/about" replace={true} />} />
          :
          <Route path="/" element={<Navigate to="/scripts" replace={true} />} />
        }

        <Route path="/auth/ms/login" element={<AzureSignIn />} />
        <Route path="/auth/ms/logout" element={<AzureSignOut />} />
      </>
    );
  }

  const overlayRoutes = () => {
    if (loggedIn) {
      return (
        <>
          <Route path="/login" element={<Navigate to="/scripts" replace={true} />} />,
          <Route path="/register" element={<Navigate to="/scripts" replace={true} />} />,
          <Route path="/resetpassword" element={<Navigate to="/scripts" replace={true} />} />
        </>
      );
    }
    return (
      <>
        <Route path="/login" element={<LoginPanel initialState='login' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />} />,
        <Route path="/register" element={<LoginPanel initialState='register' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />} />,
        <Route path="/resetpassword" element={<LoginPanel initialState='resetPassword' onClose={navigateToRoot} onAuthenticate={navigateToRoot} />} />
      </>
    );
  }

  const getNavMenuItems = () => {
    let ret = [];

    if (hasPrivilege('template')) {
      ret.push({ path: '/templates', label: 'Writing Templates' });
    }

    if (hasPrivilege('audio_library')) {
      ret.push({ path: '/audio', label: 'Audio Library' });
    }
    return ret;
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          position: 'absolute', top: '0px', left: '0px', right: '0px',
          minHeight: '100dvh',
          display: 'flex',
          justifyContent: 'center'
        }}>
        <div style={{
          position: 'fixed', top: '0px', left: '0px', right: '0px', bottom: '0px',
          backgroundImage: `url(${backgroundUrl})`,
          backgroundSize: 'cover',
        }} />
        <Box sx={{
          width: { 'xs': '100%', 'md': '60%' },
          position: 'relative',
          backgroundColor: 'white',
          display: 'flex'
        }}>
          <Suspense fallback={<div />}>
            <Routes>
              {routesWithoutHeader()}

              <Route path="*" element={
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: '100%'
                    }}>
                    <Header />

                    <Stack

                      sx={{
                        flex: '0 0 auto',
                        px: '10px',
                        py: '5px',
                        backgroundColor: theme.palette.theme.main,
                        justifyContent: { 'xs': 'center', 'md': 'start' },
                        position: 'sticky',
                        top: { 'xs': '50px', 'md': '100px' },
                        zIndex: 2,
                        height: '40px'
                      }}
                      direction='row'
                      divider={<span style={{ color: 'white' }}>|</span>}
                      alignItems="center"
                    >
                      {hasPrivilege('draft') &&
                        <Button component={Link} to='/write' style={window.location.pathname === '/write' ? selectedTabStyle : tabStyle}>Drafts</Button>
                      }
                      <Button component={Link} to='/scripts' style={window.location.pathname === '/scripts' ? selectedTabStyle : tabStyle}>Scripts</Button>
                      <Button component={Link} to='/history' style={window.location.pathname === '/history' ? selectedTabStyle : tabStyle}>History</Button>
                      <Button component={Link} to='/circle' style={window.location.pathname === '/circle' ? selectedTabStyle : tabStyle}>Friends</Button>
                      <Stack direction='column' justifyContent='center' sx={{ alignSelf: 'stretch', position: 'relative' }}>
                        <NewNotificationIndicator />
                        <Button component={Link} to='/notifications' style={window.location.pathname === '/notifications' ? selectedTabStyle : tabStyle}>Messages</Button>
                      </Stack>

                      {!isMobile &&
                        <>
                          <Box sx={{ flex: '1' }} />
                          <NavMenu
                            sx={{ p: '0px', minWidth: '0px', minHeight: '0px' }}
                            iconElement={<ListIcon htmlColor='white' fontSize='large' />}
                            mobile={false}
                            items={getNavMenuItems()}
                          />
                        </>
                      }
                    </Stack>

                    <Box sx={{ minHeight: { 'xs': 'calc(100% - 100px - 30px)', 'md': 'calc(100% - 150px - 30px)' } }}>
                      <Suspense fallback={<div />}>
                        <Routes>
                          {routesWithHeader()}
                        </Routes>
                      </Suspense>
                    </Box>
                    <Footer />
                  </Box>

                  <Suspense fallback={<div />}>
                    <Routes>
                      {overlayRoutes()}
                    </Routes>
                  </Suspense>
                </>
              } />
            </Routes>
          </Suspense>
        </Box>
      </div>
    </ThemeProvider>
  );
}